import React, { useEffect, useState, useRef } from "react";
import "./MenuCard.css"

const MenuCard = ({ data }) => {
    return (
        <div onClick={() => {window.location.href = `/products/${data.link}`}} className="Menu-Card">
            <div className={data.color ? data.color : ""}>
                <img src={data.image}></img>
            </div>
            <h5>{data.title}</h5>
        </div>
    )
}

export default MenuCard
import React, { useEffect, useState, useRef } from "react";
import "./Cart.css";
import closeIcon from "./closeicon.png";
import retailerIcon from "./retailerIcon.png";

const Cart = ({ showCart, setShowCart }) => {
    const [products, setProducts] = useState([]);
    const [recos, setRecos] = useState([]);
    const [subtotal, setSubtotal] = useState(0);
    const [changeCart, setChangeCart] = useState(false);
    //const hostname = "http://localhost:5000";
    const hostname = "https://heed.herokuapp.com";

    useEffect(() => {
        if (showCart) {
            document.getElementsByTagName("body")[0].classList.add("Cart-Open");
            // Load products from cart based off cookies
            try {
                var cartStorage = JSON.parse(localStorage.getItem('heed-cart'));
                if (cartStorage) {
                    var priceAmount = 0;
                    Object.values(cartStorage).map((product, i) => {
                        console.log("here!");
                        var price = product[0].Prices[0] * product.length;           
                        priceAmount+=price;
                    });
                    setSubtotal(priceAmount.toFixed(2));
                    setProducts(cartStorage);
                    fetchRecos();

                } else {
                    setProducts([]);
                }
            }
            catch (e) {
                console.log(e);
                console.log("here!");
            }
        } else {
            document.getElementsByTagName("body")[0].classList.remove("Cart-Open");
        }
    }, [showCart]);

    useEffect(() => {
        if (changeCart) {
            setChangeCart(false);
        } else {
            return;
        }
    }, [changeCart])


    const fetchRecos = () => {

        // Pull products
        // Fetch request
        fetch( `${hostname}/api/menu/61239787a61dca00a274ac5f/products/upsell`, {    headers: { 'Content-Type': 'application/json' }, method: "GET", crossDomain:true })
        .then((response) => response.json())
        .then((response) => {
            if (response.success) {
                const shuffled = response.data.sort(() => 0.5 - Math.random());
                let selected = shuffled.slice(0, 5);
                setRecos(selected);
            }
        })
        .catch((err) => {console.log(err)});  
    }

    const removeCartItem = (id) => {
        var newCart = products;
        delete newCart[id];
        setProducts(newCart);
        var priceAmount = 0;
        Object.values(products).map((product, i) => {
            var price = product[0].Prices[0] * product.length;           
            priceAmount+=price;
        });
        setSubtotal(priceAmount.toFixed(2));
        localStorage.setItem('heed-cart', JSON.stringify(newCart));
        setChangeCart(true);
    }

      // Add to cart function
    const addToCart = (productOpen) => {
        var cartObj = JSON.parse(localStorage.getItem('heed-cart')) ? JSON.parse(localStorage.getItem('heed-cart')) : {};
        if (!cartObj[productOpen.id]) {
            cartObj[productOpen.id] = [];
        }
        
        cartObj[productOpen.id].push(productOpen);

        var cartWeight = 0;

        Object.values(cartObj).map((productArr, i) => {
            var product = productArr[0];
            var productAmount = productArr.length;
            var netWeight = parseFloat(product.measurements) * productAmount;
            cartWeight+=netWeight;
        });

        console.log(cartWeight);

        // Calculate updated cart weight
        if (cartWeight > 30) {
            alert("Sorry! You've reached the 30g purchase limit due to federal regulations.")
        } else {
            console.log('ATC weight is', cartWeight);
            setProducts(cartObj);
            setChangeCart(true);
            localStorage.setItem('heed-cart', JSON.stringify(cartObj));
            fetchRecos();
        }
    }

    return (
        <div onClick={() => {setShowCart(false)}} style={{display: showCart ? 'block' : 'none'}} className={`Cart-Wrapper`}>
            <div onClick={(e) => {e.stopPropagation();}} className="Cart">
                <div className="Cart-Header">

                    <div>
                        <h5>Shopping Cart</h5>
                        <p>Subtotal: ${subtotal}</p>
                    </div>
                    
                    <div>
                        <button onClick={() => {setShowCart(!showCart)}}> 
                            <img src={closeIcon}></img>
                            Close
                        </button>
                    </div>

                </div>

                
                <div className="Cart-Body">
                    <div className="Cart-Branding">

                        <div style={{marginRight:15}}>
                            <img src={retailerIcon}></img>
                        </div>

                        <div>
                            <p>You're shopping at: </p>
                            <h5>{"Neat Cannabis Komoka"}</h5>
                        </div>

                        <div style={{marginLeft:'auto', marginRight:40}}>
                            <p>Pickup</p>
                        </div>

                        </div>

                        <div className="Cart-Products">
                        {
                            !changeCart && products ? (
                                <>
                                {Object.values(products).map((product, i) => (
                                    <div className="Cart-Product">
                                        <div style={{marginRight:15}}>
                                            <img src={product[0].Image}></img>
                                        </div>

                                        <div>
                                            <h5>{product[0].Name}</h5>
                                            <p>{product[0].brandName}</p>
                                            <h2>
                                                {product[0].OverridePrice ? (
                                                    <>
                                                        <span style={{marginRight:5, color: "red", textDecoration: "line-through"}}>
                                                            ${product[0].Prices[0].toFixed(2)}
                                                        </span>
                                                        ${product[0].OverridePrice.toFixed(2)}
                                                    </>
                                                ) : 
                                                    <>
                                                        ${product[0].Prices[0].toFixed(2)}
                                                    </>
                                                }
                                            </h2>
                                            <button className="Remove-Cart-Item" id={product[0].id} onClick={(e) => {removeCartItem(e.target.id)}} >remove</button>
                                        </div>

                                        <div style={{marginLeft:'auto', marginRight:0}}>
                                            <span className="Product-Amount">{product.length}</span>
                                        </div>
                                    </div>
                                ))}
                                </>
                            ) : null
                        }
                       
                    </div>

                </div>
               
                <div className="Cart-Recos">
                    {recos.length > 0 ? (
                        <p style={{fontSize: 14, fontWeight:'bold', color: '#4F4F4F', textAlign:'center'}}>Other's also liked...</p>
                    ) : null}
                    <div className="Cart-Products">
                    
                    {recos.map((product) => {
                        return (
                            
                            <div style={{height:150, minWidth:270}} className="Cart-Product">
                                <div style={{marginRight:15}}>
                                    <img src={product.Image}></img>
                                </div>

                                <div>
                                    <a className="Reco-Name" href={`/product/${product.id}`} style={{}}>{product.Name}</a>
                                    <p style={{fontSize: 10}}>{product.brandName}</p>
                                    <h2 style={{fontSize:10}}>
                                        {product.OverridePrice ? (
                                            <>
                                                <span style={{marginRight:5, color: "red", textDecoration: "line-through"}}>
                                                    ${product.Prices[0].toFixed(2)}
                                                </span>
                                                ${product.OverridePrice.toFixed(2)}
                                            </>
                                        ) : 
                                            <>
                                                ${product.Prices[0].toFixed(2)}
                                            </>
                                        }
                                    </h2>
                                    <button onClick={() => {addToCart(product)}}>Add to Cart</button>

                                </div>                            

                            </div>
                        )
                    })}
                    </div>
                </div>

                <div className="Cart-Footer">
                    {
                        products && Object.values(products).length == 0 ? (
                            <button disabled style={{opacity: 0.7, cursor:'no-drop'}} onClick={() => {window.location.href = "/checkout"}}>Procceed to Checkout</button>
                        ) : 
                        <button onClick={() => {window.location.href = "/checkout"}}>Procceed to Checkout</button>
                    }
                </div>

            </div>
        </div>
    )
}

export default Cart
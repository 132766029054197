import React, { useEffect, useState, useRef } from "react";
import "./ProductRow.css"
import plus from "../../plus.svg";

const ProductRow = ({ product, setShowCart }) => {

    // Add to cart function
    const addToCart = () => {
        var cartObj = JSON.parse(localStorage.getItem('heed-cart')) ? JSON.parse(localStorage.getItem('heed-cart')) : {};

        if (!cartObj[product.id]) {
            cartObj[product.id] = [];
        }
        
        for (var i = 0; i < parseInt(1); i++) {
            cartObj[product.id].push(product);
        }

        var cartWeight = 0;

        Object.values(cartObj).map((productArr, i) => {
            var product = productArr[0];
            var productAmount = productArr.length;
            var netWeight = parseFloat(product.measurements) * productAmount;
            cartWeight+=netWeight;
        });

        // Calculate updated cart weight
        if (cartWeight > 30) {
            alert("Sorry! You've reached the 30g purchase limit for flower due to federal regulations.")
        } else {
            localStorage.setItem('heed-cart', JSON.stringify(cartObj));
            setShowCart(true);
        }

    }

    return (
        <div  className="Product-Row">
            
            
            
            <div  style={{marginRight:15, cursor: "pointer", flex:1}} onClick={() => {window.location.href = `/product/${product.id}`}} className="Desktop-ProductRow-Image">
                <img src={product.Image}></img>
            </div>

            <div className="Wrapper">
                <div style={{cursor: "pointer", flex:1}} onClick={() => {window.location.href = `/product/${product.id}`}}>
                    <p>{product.brandName}</p>
                    <h5>{product.Name}</h5>
                    <div>
                        <p style={{fontWeight:'bold'}}>{product.strainType.toUpperCase()}
                            <>
                                {
                                    product.THCContent && product.THCContent.range && product.THCContent.range.length > 0 ? (
                                        product.THCContent.unit === "MILLIGRAMS" ? (
                                            `• THC: ${product.THCContent.range[product.THCContent.range.length-1]}mg`
                                        ) : (
                                            `• THC: ${product.THCContent.range[0]}% - ${product.THCContent.range[product.THCContent.range.length-1]}%`
                                        )
                                    ) : null
                                }
                            </>
                            <>
                                {
                                    product.CBDContent && product.CBDContent.range && product.CBDContent.range.length > 0 ? (
                                        product.CBDContent.unit === "MILLIGRAMS" ? (
                                            `• CBD: ${product.CBDContent.range[product.CBDContent.range.length-1]}mg`
                                        ) : (
                                            `• CBD: ${product.CBDContent.range[0]}% - ${product.CBDContent.range[product.CBDContent.range.length-1]}%`
                                        )
                                    ) : null
                                }
                            </>
                        </p>
                    </div>
                </div>

                <div className="Pricing">
                    <button style={{position:"relative"}} onClick={() => {addToCart()}}>
                        <>  
                           <img style={{position:"absolute", width:20, height:20, top:-5, right:-5}} src={plus}></img>
                            {
                                product.Weight && product.Weight.measure ? (
                                    <>
                                    {product.Weight.value}{product.Weight.measure} - 
                                        {product.OverridePrice ? (
                                            <>
                                                <span style={{marginRight:5, color: "red", textDecoration: "line-through"}}>
                                                    ${product.Prices[0].toFixed(2)}
                                                </span>
                                                ${product.OverridePrice.toFixed(2)}
                                            </>
                                        ) : 
                                            <>
                                                ${product.Prices[0].toFixed(2)}
                                            </>
                                        }
                                    </>
                                ) : (
                                    <>
                                        {product.OverridePrice ? (
                                            <>
                                                <span style={{marginRight:5, color: "red", textDecoration: "line-through"}}>
                                                    ${product.Prices[0].toFixed(2)}
                                                </span>
                                                ${product.OverridePrice.toFixed(2)}
                                            </>
                                        ) : 
                                            <>
                                                ${product.Prices[0].toFixed(2)}
                                            </>
                                        }
                                    </>
                                )
                            }
                        </>
                        
                    </button>                    
                </div>
            </div>

            <div style={{marginRight:'auto', cursor: "pointer", flex:1}} onClick={() => {window.location.href = `/product/${product.id}`}}  className="Mobile-ProductRow-Image">
                <img src={product.Image}></img>
            </div>


        </div>
    )
}

export default ProductRow